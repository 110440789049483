<template>
	<div class="Fileds">
		
		<div v-if="User == null">
			<div style="height: 300px;text-align: center;">
				用户信息获取中....
			</div>
		</div>
		<div class="List" v-if="User != null">
			
			<div class="One">
				<li>
					<span>头像</span>
					<em>
						
						<el-upload
						  class="avatar-uploader"
						  action="https://jsonplaceholder.typicode.com/posts/"
						  :show-file-list="false" :on-change="getFile" :limit="1" :auto-upload="false">
						<img v-if="User.Avatar != ''" :src="User.Avatar" style="width: 150px;border-radius: 75px;" />
						
						<div v-else style="width: 150px;height: 150px;background-color: rgba(0,0,0,0.1);border-radius: 75px;display: flex;align-items: center;justify-content: center;">
							<el-button circle type="primary" icon="el-icon-plus" size="mini"></el-button>
						</div>
						</el-upload>
						
					</em>
				</li>
				<li>
					<span>用户名</span>
					<em>
						{{$StrStar(User.Account)}}
					</em>
				</li>
				<li>
					<span>昵称</span>
					<em><el-input v-model="User.Name" style="width: 200px;"></el-input></em>
				</li>
				<li>
					<span>性别</span>
					<em>
						<el-radio label="male" v-model="User.Sex">男</el-radio>
						  <el-radio  label="female" v-model="User.Sex">女</el-radio>
						   <el-radio  label="unknow" v-model="User.Sex">保密</el-radio>
					</em>
				</li>
				<li v-if="1>2">
					<span>部门</span>
					<em>采购部</em>
				</li>	
				<li>
					<span>手机号</span>
					<em>{{$StrStar(User.Mobile)}}</em>
				</li>
				<li>
					<span></span>
					<em>
						<el-button type="primary" @click="UpdateUser()">提交</el-button>
					</em>
				</li>
			</div>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Radio,Upload} from 'element-ui'
	export default {
	  name: 'InfoFileds',
	  props: {
	  },
	  data() {
	      return {
			  User:null,
			  NewAvatar:'',//新头像路径,
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-radio':Radio,
		'el-upload':Upload,
	  },
	  created() {
	  	this.GetUser()
	  },
	  methods:{
		  GetUser(){
			  let data = {
				Service:'User',
				Class: 'User',
				Action: 'Get',
				Id:'',//普通用户只能调取自己的信息
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
				
				if(res.ErrorId != 0){
					this.$message(res.Msg)
					return
				}
				
				this.User = res.Data
				
			  })
			  .catch(function (response) {
				this.$message('网络请求错误')
			  })
		  },
		  UpdateUser(){
			  let data = {
				Service:'User',
				Class: 'User',
				Action: 'Update',
				Id:this.User.Id,
				Avatar:this.NewAvatar,
				Name:this.User.Name,
				Sex:this.User.Sex,
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
				this.$message(res.Msg)
			  })
		  },
		  getFile(file, fileList) {
		  				this.getBase64(file.raw).then(res => {
		  					this.uploadPic(res)
		  			    });
		  },
		  getBase64(file) {
		        return new Promise(function(resolve, reject) {
		          let reader = new FileReader();
		          let imgResult = "";
		          reader.readAsDataURL(file);
		          reader.onload = function() {
		            imgResult = reader.result;
		          };
		          reader.onerror = function(error) {
		            reject(error);
		          };
		          reader.onloadend = function() {
		            resolve(imgResult);
		          };
		        });
		      },
		  			  uploadPic(_b64){
		  				  let data = {
		  				  	Service:'File',
		  				  	Class: 'File',
		  				  	Action: 'Base64Upload',
		  				  	Base64:_b64
		  				  }
		  				  this.$post(this.$store.getters.getApiHost,data)
		  				  .then((res) => {
		  				  	
							this.$message(res.Msg)
		  				  	if(res.ErrorId != 0){
		  				  		return
		  				  	}
		  				  	
		  				  	this.NewAvatar = res.Data.Url
		  				  	this.User.Avatar = res.Data.Url
		  				  })
		  			  },
	  }
	}
</script>

<style scoped>
.Fileds{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.Fileds .List{
	margin-top: 20px;	
}
.Fileds .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.Fileds .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.Fileds .List .One li span{
	width: 100px;
	color: rgba(0,0,0,0.3);
}
.Fileds .List .One li  em{
	flex: 1;
}
.Fileds .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}
</style>
